import { redirect } from 'react-router-dom';

export const BASE_PATH = getBasePath();

function getBasePath() {
  // 2024-06-10, jonas:
  // Aktuell verewenden wir den Hash-Router ...
  // Folgende Methode um den base-path zu ermitteln funktioniert nur dem Hash-Router,
  // sobald wir auf den BrowserRouter umstellen, muss diese Methode angepasst werden ... der effizienteste Weg wird wohl sein einen ENV-Parameter zu verwenden ...

  const path = window.location.pathname;
  // window.location.pathname kann `/shop/` oder `/shop/index.html` sein ...
  return path.substring(0, path.lastIndexOf('/') + 1) + '#/';
}

export const getQueryParameterByName = (name: string, url?: string): string | undefined => {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return undefined;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function redirectIfPathAndQueryParamsAreNotIdentical(url1: string, url2: string) {
  const parseUrl = (url: string) => {
    const { pathname, searchParams } = new URL(url, 'https://www.transgourmet.ch'); // Use a dummy base URL for parsing
    const sortedParams = new URLSearchParams(Array.from(searchParams.entries()).sort());
    return { pathname, sortedParams: sortedParams.toString() };
  };

  const { pathname: pathname1, sortedParams: sortedParams1 } = parseUrl(url1);
  const { pathname: pathname2, sortedParams: sortedParams2 } = parseUrl(url2);

  const identical = pathname1 === pathname2 && sortedParams1 === sortedParams2;
  if (!identical) {
    return redirect(url2);
  }
}
