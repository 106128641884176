import type { IArticleDetailDTOData, IArticleDTOData, IPriceStepDTOData } from '../../../../api';
import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18next';
import { getMLSelector } from '../../../../util/i18n';
import { PPCONTEXT } from '../../../../util/ppContext';
import { fixCommas } from '../../../../util/stringUtil';
import { FactDocument } from './factDocument';
import { FactPicto } from './factPicto';
import { ppConstants } from '../../../../util/ppConstants';
import axios from '../../../../util/axios';
import { formatDate } from '../../../../util/dateUtils';
import { executeAsyncAction } from '../../../../util/exceptionHandler';
import { buildArticleImageUrlFromArticleNumber, buildArticleThumbnailUrlFromArticleNumber } from '../../../../util/articleImage';

export interface NutritionalValue {
  valueKey: string;
  labelKey: string;
}

export const nutritionalValues: NutritionalValue[] = [
  { valueKey: 'energyKj', labelKey: 'Energiewert kJ' },
  { valueKey: 'energyKcal', labelKey: 'Energiewert kcal' },
  { valueKey: 'fat', labelKey: 'Fett' },
  { valueKey: 'saturatedFatty', labelKey: 'davon gesättigte Fettsäuren' },
  {
    valueKey: 'monoUnsaturatedFatty',
    labelKey: 'davon einfach ungesättigte Fettsäuren'
  },
  {
    valueKey: 'polyUnsaturatedFatty',
    labelKey: 'davon mehrfach ungesättigte Fettsäuren'
  },
  { valueKey: 'carbohydrates', labelKey: 'Kohlenhydrate' },
  { valueKey: 'sugar', labelKey: 'davon Zucker' },
  { valueKey: 'dietaryfibres', labelKey: 'Nahrungsfasern (Ballaststoffe)' },
  { valueKey: 'protein', labelKey: 'Eiweiss' },
  { valueKey: 'salt', labelKey: 'Salz' }
];

export const OILBARREL_ARTICLE_NUMBER = '999054';

export class Article {
  get isZzArticle() {
    return this.zzArticles && this.zzArticles.length > 1;
  }

  get hasMoreThanTwoZzArticles() {
    return this.isZzArticle && this.zzArticles.length > 2;
  }

  get hasSubstituteArticle() {
    return this.substituteArticle !== undefined || this.substituteArticleId !== undefined;
  }

  get formattedSellUnitAmount() {
    const sellUnit = this.sellAmount > 1 ? this.sellUnit + ` ${i18n.t('sellUnitSeparator')} ` : '';
    const sellAmount = this.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? this.approxWeight : this.sellAmount;
    const articleUnit = ' ' + this.articleUnit;
    return `${sellUnit}${sellAmount}${articleUnit}`;
  }

  get thumbImageUrl() {
    let articleNumber: string;
    if (this.isZzArticle) {
      let smallestSellAmountZZArticle = null;
      for (const zzArt of this.zzArticles) {
        if (!smallestSellAmountZZArticle || smallestSellAmountZZArticle.sellAmount > zzArt.sellAmount) {
          smallestSellAmountZZArticle = zzArt;
        }
      }
      articleNumber = smallestSellAmountZZArticle!.articleNumber;
    } else {
      articleNumber = this.articleNumber;
    }

    const thumbnailUrl = buildArticleThumbnailUrlFromArticleNumber(articleNumber);
    return thumbnailUrl;
  }

  get imageUrl() {
    let articleNumber: string;
    if (this.isZzArticle) {
      let smallestSellAmountZZArticle = null;
      for (const zzArt of this.zzArticles) {
        if (!smallestSellAmountZZArticle || smallestSellAmountZZArticle.sellAmount > zzArt.sellAmount) {
          smallestSellAmountZZArticle = zzArt;
        }
      }
      articleNumber = smallestSellAmountZZArticle!.articleNumber;
    } else {
      articleNumber = this.articleNumber;
    }

    const imageUrl = buildArticleImageUrlFromArticleNumber(articleNumber);
    return imageUrl;
  }

  get brandLabelImageUrl() {
    if (this.brandLabel) {
      return PPCONTEXT.STATIC_IMAGES_BASE_URL + '/logo/' + this.brandLabel + '.jpg';
    }

    return undefined;
  }

  get exclusivenessImageUrl() {
    if (this.exclusiveness) {
      return PPCONTEXT.STATIC_IMAGES_BASE_URL + '/webshop/exclusiveness.jpg';
    }

    return undefined;
  }

  get showAddToTemplateLink() {
    return this.articleStatusAvailable && this.mainArticleKind !== 7 && this.mainArticleKind !== 8 && !this.notInWebShop;
  }

  get articleStatusAvailable() {
    return this.articleStatus !== Article.ArticleStatusBlank && this.articleStatus !== Article.ArticleStatusStar;
  }

  get showAddToInventoryLink() {
    return this.articleStatus !== Article.ArticleStatusBlank && this.mainArticleKind !== 7 && this.mainArticleKind !== 8;
  }

  get hideCart() {
    return this.articleStatus === Article.ArticleStatusBlank || this.articleStatus === Article.ArticleStatusStar || !this.available || this.mainArticleKind === 7 || this.mainArticleKind === 8 || this.notInWebShop || this.stopselling;
  }

  get detailLink() {
    return '/catalog/' + this.articleNumber;
  }

  get showFactSheet() {
    return this.qsReleased && this.hwgId !== 2 && this.hwgId !== 9 && this.hwgId !== 10;
  }

  get grapesFlatString() {
    return Article.flattenString(this.grapes);
  }

  get fitsWellWithFlatString() {
    return Article.flattenString(this.fitsWellWith);
  }

  get fishingTechniqueFlatString() {
    return Article.flattenString(this.fishingTechnique);
  }

  get fishingZoneFlatString() {
    const fishingZone = Article.flattenString(this.fishingZone);
    let displayValue = '';
    if (fishingZone && fishingZone.length > 0) {
      displayValue += `${i18n.t('Gefangen in')}: ${fishingZone}`;
      if (this.inAquaKulturGewonnen) {
        const aquaKultur = `${i18n.t('In Aquakultur gewonnen')}`;
        if (aquaKultur) {
          displayValue += ` (${aquaKultur.charAt(0).toLowerCase() + aquaKultur.slice(1)})`;
        }
      }
    } else if (this.inAquaKulturGewonnen) {
      displayValue += `${i18n.t('In Aquakultur gewonnen')}`;
    }

    return displayValue;
  }

  get allergensContainFlatString() {
    return Article.flattenString(this.allergensContain);
  }

  get allergensMayContainFlatString() {
    return Article.flattenString(this.allergensMayContain);
  }

  get templateItems(): Article[] | undefined {
    return this.isZzArticle ? this.zzArticles : undefined;
  }

  get showOrderDetails() {
    if (this.articleStatusAvailable && this.available) {
      return true;
    }

    return !this.hasSubstituteArticle;
  }

  get showReplaceArticleActions() {
    if ((this.articleStatus === Article.ArticleStatusBlank || this.articleStatus === Article.ArticleStatusStar) && this.hasSubstituteArticle) {
      return true;
    }

    if (!this.available) {
      return false;
    }

    return false;
  }

  get hasBrand(): boolean {
    return !!this.brand && this.brand.trim().length > 0;
  }

  calculatedTotalPrice(actionExclude?: boolean): string {
    const article = this.articleOrZzArticle;
    let priceValue = actionExclude ? article.regularPrice : article.effectivePrice;
    if (article.priceSteps && article.priceSteps.length > 0) {
      for (const step of article.priceSteps) {
        const actQuantity = article.amount * (article.sellAmount * (article.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? article.approxWeight : 1));
        const quantity = step.quantity;
        const price = step.price;

        if (actQuantity >= quantity) {
          priceValue = price;
        }
      }
    }

    return `CHF ${(article.amount * (article.sellAmount * (article.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? article.approxWeight : 1) * priceValue)).toFixed(2)}`;
  }

  get articleOrZzArticle(): Article {
    const article = this.isZzArticle ? this.zzArticles[this.zzIndex] : this;
    return article;
  }

  get alcoholPercentage() {
    return (this.alcoholVolPercent * 100).toFixed(1);
  }

  get formattedAlcoholPercentage() {
    return this.alcoholPercentage + '%';
  }

  get showAlcoholPercentage() {
    return (this.hwgId === 3 || this.hwgId === 4 || this.hwgId === 5) && parseFloat(this.alcoholPercentage) > 0;
  }

  get isAvailableAndCanBeOrdered() {
    return this.articleStatus !== Article.ArticleStatusStar && this.articleStatus !== Article.ArticleStatusBlank && this.available;
  }

  static ArticleStatusStar: string = '*';
  static ArticleStatusBlank: string = ' ';

  static createFromDto(dto: IArticleDTOData, amount?: number): Article {
    const mls = getMLSelector();
    const catalogArticle = new Article(dto.articleId);
    catalogArticle.factDocuments = [];
    catalogArticle.factPictos = [];
    catalogArticle.foodFact = undefined;
    catalogArticle.ecoscoreLabel = dto.ecoscoreLabel;
    catalogArticle.sustainabilityDatasheetUrl = dto.sustainabilityDatasheetUrl;
    catalogArticle.factRegion = mls([dto.factRegionDE, dto.factRegionFR, dto.factRegionIT]);
    catalogArticle.articleNumber = dto.articleNumber ? dto.articleNumber : '';
    catalogArticle.approxWeight = dto.approxWeight ? dto.approxWeight : 0;
    catalogArticle.articleActionValidTo = dto.articleActionValidTo ? new Date(dto.articleActionValidTo as any) : undefined;
    catalogArticle.articleUnit = dto.articleUnit ? mls(dto.articleUnit) : '';
    catalogArticle.brand = dto.brand ? dto.brand : '';
    catalogArticle.exclusiveness = dto.exclusiveness;
    catalogArticle.priceCode = dto.priceCode ? dto.priceCode : 0;
    catalogArticle.sellAmount = dto.sellAmount ? dto.sellAmount : 0;
    catalogArticle.mainArticleId = dto.mainArticleId ? dto.mainArticleId : 0;
    catalogArticle.hagr = dto.hagr ? mls(dto.hagr) : '';
    catalogArticle.articleText = dto.articleText ? mls(dto.articleText) : '';
    catalogArticle.brandLabel = dto.brandLabel ? dto.brandLabel : undefined;
    catalogArticle.wineVintage = dto.wineVintage ? dto.wineVintage : undefined;
    catalogArticle.drainedNetWeight = dto.drainedWeight ? dto.drainedWeight : undefined;
    catalogArticle.storability = dto.storability ? dto.storability : undefined;
    catalogArticle.servingTemperature = dto.servingTemperature ? dto.servingTemperature : undefined;
    catalogArticle.grapes = mls([dto.grapesDE, dto.grapesFR, dto.grapesIT]);
    catalogArticle.fitsWellWith = mls([dto.fitsWellWithDE, dto.fitsWellWithFR, dto.fitsWellWithIT]);
    catalogArticle.fishingZone = mls([dto.fishingZoneDE, dto.fishingZoneFR, dto.fishingZoneIT]);
    catalogArticle.fishingTechnique = mls([dto.fishingTechniqueDE, dto.fishingTechniqueFR, dto.fishingTechniqueIT]);
    catalogArticle.inAquaKulturGewonnen = dto.inAquaKulturGewonnen;
    catalogArticle.orderNote = dto.orderNote ? mls(dto.orderNote) : undefined;
    catalogArticle.description = dto.description ? mls(dto.description) : undefined;
    catalogArticle.specialDiet = dto.specialDiet ? mls(dto.specialDiet) : undefined;
    catalogArticle.sellUnit = dto.sellUnit ? mls(dto.sellUnit) : undefined;
    catalogArticle.herkunftLabel = dto.herkunftLabel ? dto.herkunftLabel : undefined;
    catalogArticle.herkunftLabelText = mls([dto.herkunftLabelDE, dto.herkunftLabelFR, dto.herkunftLabelIT]);
    catalogArticle.fairtradeLabel = dto.fairtradeLabel ? dto.fairtradeLabel : undefined;
    catalogArticle.fairtradeLabelText = mls([dto.fairtradeLabelDE, dto.fairtradeLabelFR, dto.fairtradeLabelIT]);
    catalogArticle.nachhaltigkeitLabel = dto.nachhaltigkeitLabel ? dto.nachhaltigkeitLabel : undefined;
    catalogArticle.nachhaltigkeitLabelText = mls([dto.nachhaltigkeitLabelDE, dto.nachhaltigkeitLabelFR, dto.nachhaltigkeitLabelIT]);
    catalogArticle.spezialproduktLabel = dto.spezialproduktLabel ? dto.spezialproduktLabel : undefined;
    catalogArticle.spezialproduktLabelText = mls([dto.spezialproduktLabelDE, dto.spezialproduktLabelFR, dto.spezialproduktLabelIT]);
    catalogArticle.bioLabel = dto.bioLabel ? dto.bioLabel : undefined;
    catalogArticle.bioLabelText = mls([dto.bioLabelDE, dto.bioLabelFR, dto.bioLabelIT]);
    catalogArticle.palmoelLabel = dto.palmoelLabel ? dto.palmoelLabel : undefined;
    catalogArticle.palmoelLabelText = mls([dto.palmoelLabelDE, dto.palmoelLabelFR, dto.palmoelLabelIT]);
    catalogArticle.fakturaText = mls([dto.fakturaTextDE, dto.fakturaTextFR, dto.fakturaTextIT]);
    catalogArticle.noveltyUntil = dto.noveltyUntil;
    catalogArticle.articleAssortmentIndex = dto.articleAssortmentIndex ? dto.articleAssortmentIndex : [];
    catalogArticle.zzArticles = dto.zzArticles && dto.zzArticles.length > 0 ? dto.zzArticles.map((a) => Article.createFromDto(a, amount)) : [];
    catalogArticle.articleStatus = dto.articleStatus ? dto.articleStatus : '';
    catalogArticle.mainArticleKind = dto.mainArticleKind ? dto.mainArticleKind : undefined;
    catalogArticle.amount = amount ? amount : dto.amount ? dto.amount : 0;
    catalogArticle.externalArticle = dto.externalArticle;
    catalogArticle.available = dto.available;
    catalogArticle.priceSteps = dto.priceSteps ? dto.priceSteps : undefined;
    catalogArticle.specialNextDeliveryDate = dto.specialNextDeliveryDate ? new Date(dto.specialNextDeliveryDate as any) : undefined;
    catalogArticle.rohstoffHerkunft = dto.rohstoffHerkunft ? fixCommas(mls(dto.rohstoffHerkunft)) : undefined;
    catalogArticle.herkunftHergestelltIn = dto.herkunftHergestelltIn ? fixCommas(mls(dto.herkunftHergestelltIn)) : undefined;
    catalogArticle.herkunftAbgefuellt = dto.herkunftAbgefuellt ? fixCommas(mls(dto.herkunftAbgefuellt)) : undefined;
    catalogArticle.herkunftAbgepacktIn = dto.herkunftAbgepacktIn ? fixCommas(mls(dto.herkunftAbgepacktIn)) : undefined;
    catalogArticle.aufgetaut = dto.aufgetaut ? fixCommas(mls(dto.aufgetaut)) : undefined;
    catalogArticle.lateinName = dto.lateinName ? dto.lateinName : undefined;
    catalogArticle.supplierGroupMinOrderValue = dto.supplierGroupMinOrderValue ? dto.supplierGroupMinOrderValue : undefined;
    catalogArticle.supplierGroupName = dto.supplierGroupName ? dto.supplierGroupName : undefined;
    catalogArticle.supplierGroupTermsDocumentName = dto.supplierGroupTermsDocumentName ? dto.supplierGroupTermsDocumentName : undefined;
    catalogArticle.qsReleased = dto.qsReleased;
    catalogArticle.hwgId = dto.hwgId;
    catalogArticle.allergensContain = mls([dto.allergenContainDE, dto.allergenContainFR, dto.allergenContainIT]);
    catalogArticle.allergensMayContain = mls([dto.allergenMayContainDE, dto.allergenMayContainFR, dto.allergenMayContainIT]);
    catalogArticle.ingredients = mls(dto.ingredients) ? mls(dto.ingredients) : dto.ingredients[0];
    catalogArticle.energyKcal = dto.energyKcal ? dto.energyKcal : undefined;
    catalogArticle.carbohydrates = dto.carbohydrates ? dto.carbohydrates : undefined;
    catalogArticle.energyKj = dto.energyKj ? dto.energyKj : undefined;
    catalogArticle.sugar = dto.sugar ? dto.sugar : undefined;
    catalogArticle.fat = dto.fat ? dto.fat : undefined;
    catalogArticle.dietaryfibres = dto.dietaryfibres ? dto.dietaryfibres : undefined;
    catalogArticle.saturatedFatty = dto.saturatedFatty ? dto.saturatedFatty : undefined;
    catalogArticle.monoUnsaturatedFatty = dto.monoUnsaturatedFatty ? dto.monoUnsaturatedFatty : undefined;
    catalogArticle.polyUnsaturatedFatty = dto.polyUnsaturatedFatty ? dto.polyUnsaturatedFatty : undefined;
    catalogArticle.salt = dto.salt ? dto.salt : undefined;
    catalogArticle.protein = dto.protein ? dto.protein : undefined;
    catalogArticle.storage = mls(dto.storage) ? mls(dto.storage) : dto.storage[0];
    catalogArticle.durability = mls(dto.durability) ? mls(dto.durability) : dto.durability[0];
    catalogArticle.preparation = mls(dto.preparation) ? mls(dto.preparation) : dto.preparation[0];
    catalogArticle.hwg = dto.hwg ? mls(dto.hwg) : '';
    catalogArticle.agr = dto.agr ? mls(dto.agr) : '';
    catalogArticle.managedAssortmentGroupId = dto.managedAssortmentGroupId ? dto.managedAssortmentGroupId : undefined;
    catalogArticle.assortmentIndex = dto.assortmentIndex;
    catalogArticle.ledgerAccount = dto.ledgerAccount ? dto.ledgerAccount : undefined;
    catalogArticle.ean = dto.ean;
    catalogArticle.notInWebShop = dto.notInWebShop;
    catalogArticle.inSalesOrderSet = dto.notInWebShop;

    catalogArticle.substituteArticleId = dto.substituteArticleId ? dto.substituteArticleId : undefined;

    catalogArticle.regularPrice = dto.regularPrice;
    catalogArticle.effectivePrice = dto.effectivePrice;
    if (dto.articleNumber === OILBARREL_ARTICLE_NUMBER) {
      catalogArticle.effectivePrice = 0;
    }
    catalogArticle.isAction = dto.isAction;
    catalogArticle.isIndividualPrice = dto.isIndividualPrice;

    catalogArticle.alcoholVolPercent = dto.alcoholVolPercent;
    catalogArticle.showAction = dto.showAction;

    catalogArticle.stopselling = dto.stopselling;

    catalogArticle.zzArticles = catalogArticle.zzArticles?.slice().sort((a: Article, b: Article) => parseInt(a.articleNumber) - parseInt(b.articleNumber));

    catalogArticle.orderEndTimes = dto.orderEndTimes;

    return catalogArticle;
  }

  private static flattenString(value?: string[]) {
    if (value && value.length > 0) {
      return value.join(', ');
    }
    return undefined;
  }

  isSelected: boolean;
  articleId!: number;
  mainArticleId!: number;
  articleNumber!: string;
  articleActionValidTo?: Date;
  articleUnit: string = '';
  priceCode!: number;
  approxWeight!: number;
  sellAmount!: number;
  exclusiveness!: boolean;
  brand: string = '';
  zzArticles!: Article[];
  factDocuments!: FactDocument[];
  factPictos!: FactPicto[];
  foodFact: string | undefined;
  ecoscoreLabel: string | undefined;
  sustainabilityDatasheetUrl: string | undefined;
  factRegion?: string;
  hagr!: string;
  articleText!: string;
  brandLabel?: number;
  wineVintage?: number;
  drainedNetWeight?: string;
  storability?: string;
  servingTemperature?: string;
  grapes?: string[];
  fitsWellWith?: string[];
  fishingZone?: string[];
  fishingTechnique?: string[];
  inAquaKulturGewonnen!: boolean;
  orderNote?: string;
  description?: string;
  specialDiet?: string;
  sellUnit?: string;
  herkunftLabel?: number;
  herkunftLabelText?: string;
  fairtradeLabel?: number;
  fairtradeLabelText?: string;
  nachhaltigkeitLabel?: number;
  nachhaltigkeitLabelText?: string;
  spezialproduktLabel?: number;
  spezialproduktLabelText?: string;
  bioLabel?: number;
  bioLabelText?: string;
  palmoelLabel?: number;
  palmoelLabelText?: string;
  fakturaText?: string;
  noveltyUntil!: boolean;
  articleAssortmentIndex!: number[];
  articleStatus!: string;
  mainArticleKind?: number;
  amount!: number;
  externalArticle!: boolean;
  available!: boolean;
  priceSteps?: IPriceStepDTOData[];
  specialNextDeliveryDate?: Date;
  rohstoffHerkunft: string | undefined;
  herkunftHergestelltIn: string | undefined;
  herkunftAbgefuellt: string | undefined;
  herkunftAbgepacktIn: string | undefined;
  aufgetaut: string | undefined;
  lateinName: string | undefined;
  supplierGroupMinOrderValue?: number;
  supplierGroupName?: string;
  supplierGroupTermsDocumentName?: string;
  qsReleased!: boolean;
  hwgId!: number;
  allergensContain!: string[];
  allergensMayContain!: string[];
  ingredients!: string;
  energyKcal?: string;
  carbohydrates?: string;
  energyKj?: string;
  sugar?: string;
  fat?: string;
  dietaryfibres?: string;
  saturatedFatty?: string;
  monoUnsaturatedFatty?: string;
  polyUnsaturatedFatty?: string;
  salt?: string;
  protein?: string;
  storage!: string;
  durability!: string;
  preparation!: string;
  hwg!: string;
  agr!: string;
  managedAssortmentGroupId?: number;
  assortmentIndex!: number;
  ledgerAccount?: string;
  isDeepLoaded!: boolean;
  _zzIndex: number = 0;
  ean!: string;
  notInWebShop!: boolean;
  inSalesOrderSet!: boolean;

  substituteArticle?: Article;
  substituteArticleId?: number;

  regularPrice!: number;
  effectivePrice!: number;
  isAction!: boolean;
  isIndividualPrice!: boolean;
  isDeepLoading!: boolean;
  comparisonPrice!: number;
  hasComparisonPrice!: number;
  comparisonPriceUnit!: string;

  alcoholVolPercent!: number;
  showAction!: boolean;
  stopselling!: boolean;
  orderEndTimes?: string[];

  constructor(id?: number) {
    makeAutoObservable(this);
    if (id) {
      this.articleId = id;
    }

    this.isSelected = false;
  }

  update(data: IArticleDetailDTOData, amount?: number) {
    const mls = getMLSelector();
    this.setValueWhenChanged('articleId', data.article.articleId);
    this.setValueWhenChanged('substituteArticle', data.substituteArticle ? Article.createFromDto(data.substituteArticle) : undefined);
    this.setValueWhenChanged('factDocuments', data.factDocuments && data.factDocuments.length > 0 ? data.factDocuments.map((fd) => new FactDocument(fd)) : []);
    this.setValueWhenChanged('factPictos', data.pictos && data.pictos.length > 0 ? data.pictos.map((fp) => new FactPicto(fp)) : []);
    this.setValueWhenChanged('foodFact', data.foodFact ? data.foodFact : undefined);
    this.setValueWhenChanged('ecoscoreLabel', data.article.ecoscoreLabel ? data.article.ecoscoreLabel : undefined);
    this.setValueWhenChanged('sustainabilityDatasheetUrl', data.article.sustainabilityDatasheetUrl ? data.article.sustainabilityDatasheetUrl : undefined);
    this.setValueWhenChanged('factRegion', mls([data.article.factRegionDE, data.article.factRegionFR, data.article.factRegionIT]));
    this.setValueWhenChanged('approxWeight', data.article.approxWeight ? data.article.approxWeight : 0);
    this.setValueWhenChanged('articleActionValidTo', data.article.articleActionValidTo ? new Date(data.article.articleActionValidTo as any) : undefined);
    this.setValueWhenChanged('articleUnit', data.article.articleUnit ? mls(data.article.articleUnit) : '');
    this.setValueWhenChanged('brand', data.article.brand ? data.article.brand : '');
    this.setValueWhenChanged('exclusiveness', data.article.exclusiveness);
    this.setValueWhenChanged('priceCode', data.article.priceCode ? data.article.priceCode : 0);
    this.setValueWhenChanged('sellAmount', data.article.sellAmount ? data.article.sellAmount : 0);
    this.setValueWhenChanged('mainArticleId', data.article.mainArticleId ? data.article.mainArticleId : 0);
    this.setValueWhenChanged('hagr', data.article.hagr ? mls(data.article.hagr) : '');
    this.setValueWhenChanged('articleText', data.article.articleText ? mls(data.article.articleText) : '');
    this.setValueWhenChanged('brandLabel', data.article.brandLabel ? data.article.brandLabel : undefined);
    this.setValueWhenChanged('wineVintage', data.article.wineVintage ? data.article.wineVintage : undefined);
    this.setValueWhenChanged('drainedNetWeight', data.article.drainedWeight ? data.article.drainedWeight : undefined);
    this.setValueWhenChanged('storability', data.article.storability ? data.article.storability : undefined);
    this.setValueWhenChanged('servingTemperature', data.article.servingTemperature ? data.article.servingTemperature : undefined);
    this.setValueWhenChanged('grapes', mls([data.article.grapesDE, data.article.grapesFR, data.article.grapesIT]));
    this.setValueWhenChanged('fitsWellWith', mls([data.article.fitsWellWithDE, data.article.fitsWellWithFR, data.article.fitsWellWithIT]));
    this.setValueWhenChanged('fishingZone', mls([data.article.fishingZoneDE, data.article.fishingZoneFR, data.article.fishingZoneIT]));
    this.setValueWhenChanged('fishingTechnique', mls([data.article.fishingTechniqueDE, data.article.fishingTechniqueFR, data.article.fishingTechniqueIT]));
    this.setValueWhenChanged('inAquaKulturGewonnen', data.article.inAquaKulturGewonnen);

    this.setValueWhenChanged('orderNote', data.article.orderNote ? mls(data.article.orderNote) : undefined);
    this.setValueWhenChanged('description', data.article.description ? mls(data.article.description) : undefined);
    this.setValueWhenChanged('specialDiet', data.article.specialDiet ? mls(data.article.specialDiet) : undefined);
    this.setValueWhenChanged('sellUnit', data.article.sellUnit ? mls(data.article.sellUnit) : undefined);
    this.setValueWhenChanged('herkunftLabel', data.article.herkunftLabel ? data.article.herkunftLabel : undefined);
    this.setValueWhenChanged('herkunftLabelText', mls([data.article.herkunftLabelDE, data.article.herkunftLabelFR, data.article.herkunftLabelIT]));
    this.setValueWhenChanged('fairtradeLabel', data.article.fairtradeLabel ? data.article.fairtradeLabel : undefined);
    this.setValueWhenChanged('fairtradeLabelText', mls([data.article.fairtradeLabelDE, data.article.fairtradeLabelFR, data.article.fairtradeLabelIT]));
    this.setValueWhenChanged('nachhaltigkeitLabel', data.article.nachhaltigkeitLabel ? data.article.nachhaltigkeitLabel : undefined);
    this.setValueWhenChanged('nachhaltigkeitLabelText', mls([data.article.nachhaltigkeitLabelDE, data.article.nachhaltigkeitLabelFR, data.article.nachhaltigkeitLabelIT]));
    this.setValueWhenChanged('spezialproduktLabel', data.article.spezialproduktLabel ? data.article.spezialproduktLabel : undefined);
    this.setValueWhenChanged('spezialproduktLabelText', mls([data.article.spezialproduktLabelDE, data.article.spezialproduktLabelFR, data.article.spezialproduktLabelIT]));
    this.setValueWhenChanged('bioLabel', data.article.bioLabel ? data.article.bioLabel : undefined);
    this.setValueWhenChanged('bioLabelText', mls([data.article.bioLabelDE, data.article.bioLabelFR, data.article.bioLabelIT]));
    this.setValueWhenChanged('palmoelLabel', data.article.palmoelLabel ? data.article.palmoelLabel : undefined);
    this.setValueWhenChanged('palmoelLabelText', mls([data.article.palmoelLabelDE, data.article.palmoelLabelFR, data.article.palmoelLabelIT]));
    this.setValueWhenChanged('fakturaText', mls([data.article.fakturaTextDE, data.article.fakturaTextFR, data.article.fakturaTextIT]));
    this.setValueWhenChanged('noveltyUntil', data.article.noveltyUntil);
    this.setValueWhenChanged('articleAssortmentIndex', data.article.articleAssortmentIndex ? data.article.articleAssortmentIndex : []);
    this.setValueWhenChanged('articleStatus', data.article.articleStatus ? data.article.articleStatus : '');
    this.setValueWhenChanged('mainArticleKind', data.article.mainArticleKind ? data.article.mainArticleKind : undefined);
    this.setValueWhenChanged('externalArticle', data.article.externalArticle);
    this.setValueWhenChanged('available', data.article.available);
    this.setValueWhenChanged('priceSteps', data.article.priceSteps ? data.article.priceSteps : undefined);
    this.setValueWhenChanged('specialNextDeliveryDate', data.article.specialNextDeliveryDate ? new Date(data.article.specialNextDeliveryDate as any) : undefined);
    this.setValueWhenChanged('rohstoffHerkunft', data.article.rohstoffHerkunft ? fixCommas(mls(data.article.rohstoffHerkunft)) : undefined);
    this.setValueWhenChanged('herkunftHergestelltIn', data.article.herkunftHergestelltIn ? fixCommas(mls(data.article.herkunftHergestelltIn)) : undefined);
    this.setValueWhenChanged('herkunftAbgefuellt', data.article.herkunftAbgefuellt ? fixCommas(mls(data.article.herkunftAbgefuellt)) : undefined);
    this.setValueWhenChanged('herkunftAbgepacktIn', data.article.herkunftAbgepacktIn ? fixCommas(mls(data.article.herkunftAbgepacktIn)) : undefined);
    this.setValueWhenChanged('aufgetaut', data.article.aufgetaut ? fixCommas(mls(data.article.aufgetaut)) : undefined);
    this.setValueWhenChanged('lateinName', data.article.lateinName ? data.article.lateinName : undefined);
    this.setValueWhenChanged('supplierGroupMinOrderValue', data.article.supplierGroupMinOrderValue ? data.article.supplierGroupMinOrderValue : undefined);
    this.setValueWhenChanged('supplierGroupName', data.article.supplierGroupName ? data.article.supplierGroupName : undefined);
    this.setValueWhenChanged('supplierGroupTermsDocumentName', data.article.supplierGroupTermsDocumentName ? data.article.supplierGroupTermsDocumentName : undefined);
    this.setValueWhenChanged('qsReleased', data.article.qsReleased);
    this.setValueWhenChanged('hwgId', data.article.hwgId);
    this.setValueWhenChanged('allergensContain', mls([data.article.allergenContainDE, data.article.allergenContainFR, data.article.allergenContainIT]));
    this.setValueWhenChanged('allergensMayContain', mls([data.article.allergenMayContainDE, data.article.allergenMayContainFR, data.article.allergenMayContainIT]));
    this.setValueWhenChanged('ingredients', mls(data.article.ingredients) ? mls(data.article.ingredients) : data.article.ingredients[0]);
    this.setValueWhenChanged('energyKcal', data.article.energyKcal ? data.article.energyKcal : undefined);
    this.setValueWhenChanged('carbohydrates', data.article.carbohydrates ? data.article.carbohydrates : undefined);
    this.setValueWhenChanged('energyKj', data.article.energyKj ? data.article.energyKj : undefined);
    this.setValueWhenChanged('sugar', data.article.sugar ? data.article.sugar : undefined);
    this.setValueWhenChanged('fat', data.article.fat ? data.article.fat : undefined);
    this.setValueWhenChanged('dietaryfibres', data.article.dietaryfibres ? data.article.dietaryfibres : undefined);
    this.setValueWhenChanged('saturatedFatty', data.article.saturatedFatty ? data.article.saturatedFatty : undefined);
    this.setValueWhenChanged('monoUnsaturatedFatty', data.article.monoUnsaturatedFatty ? data.article.monoUnsaturatedFatty : undefined);
    this.setValueWhenChanged('polyUnsaturatedFatty', data.article.polyUnsaturatedFatty ? data.article.polyUnsaturatedFatty : undefined);
    this.setValueWhenChanged('salt', data.article.salt ? data.article.salt : undefined);
    this.setValueWhenChanged('protein', data.article.protein ? data.article.protein : undefined);
    this.setValueWhenChanged('storage', mls(data.article.storage) ? mls(data.article.storage) : data.article.storage[0]);
    this.setValueWhenChanged('durability', mls(data.article.durability) ? mls(data.article.durability) : data.article.durability[0]);
    this.setValueWhenChanged('preparation', mls(data.article.preparation) ? mls(data.article.preparation) : data.article.preparation[0]);
    this.setValueWhenChanged('hwg', data.article.hwg ? mls(data.article.hwg) : '');
    this.setValueWhenChanged('agr', data.article.agr ? mls(data.article.agr) : '');
    this.setValueWhenChanged('comparisonPrice', data.article.comparisonPrice ? data.article.comparisonPrice : 0);
    this.setValueWhenChanged('hasComparisonPrice', data.article.hasComparisonPrice);
    this.setValueWhenChanged('comparisonPriceUnit', mls([data.article.comparisonPriceArticleUnitDe, data.article.comparisonPriceArticleUnitFr, data.article.comparisonPriceArticleUnitIt]));

    if (data.article.managedAssortmentGroupId && data.article.managedAssortmentGroupId !== this.managedAssortmentGroupId) {
      // not set in deep load
      this.managedAssortmentGroupId = data.article.managedAssortmentGroupId ? data.article.managedAssortmentGroupId : undefined;
    }
    if (data.article.assortmentIndex) {
      this.assortmentIndex = data.article.assortmentIndex;
    }

    this.setValueWhenChanged('ledgerAccount', data.article.ledgerAccount ? data.article.ledgerAccount : undefined);
    this.setValueWhenChanged('notInWebShop', data.article.notInWebShop);
    this.setValueWhenChanged('inSalesOrderSet', data.article.inSalesOrderSet);
    this.setValueWhenChanged('ean', data.article.ean);
    this.setValueWhenChanged('regularPrice', data.article.regularPrice);
    this.setValueWhenChanged('effectivePrice', data.article.effectivePrice);
    if (this.articleNumber === OILBARREL_ARTICLE_NUMBER) {
      this.setValueWhenChanged('effectivePrice', 0);
    }

    this.setValueWhenChanged('isAction', data.article.isAction);
    this.setValueWhenChanged('isIndividualPrice', data.article.isIndividualPrice);

    this.setValueWhenChanged('alcoholVolPercent', data.article.alcoholVolPercent);
    this.setValueWhenChanged('showAction', data.article.showAction);
    this.setValueWhenChanged('stopselling', data.article.stopselling);
    this.setValueWhenChanged('orderEndTimes', data.article.orderEndTimes);

    this.zzArticles = this.zzArticles?.sort((a: Article, b: Article) => parseInt(a.articleNumber) - parseInt(b.articleNumber));

    this.isDeepLoaded = true;

    if (amount) {
      this.amount = amount;
    }
  }

  private setValueWhenChanged(this: any, fieldName: string, newValue: any) {
    if (!this[fieldName]) {
      this[fieldName] = newValue;
    } else if (this[fieldName] !== newValue) {
      if (Array.isArray(newValue)) {
        if (this[fieldName].length === newValue.length) {
          return;
        }
      }
      this[fieldName] = newValue;
    }
  }

  updateForOrderPresentation(dto: IArticleDTOData, amount?: number) {
    const mls = getMLSelector();
    this.articleId = dto.articleId;
    this.articleNumber = dto.articleNumber ? dto.articleNumber : '';
    this.approxWeight = dto.approxWeight ? dto.approxWeight : 0;
    this.articleActionValidTo = dto.articleActionValidTo ? new Date(dto.articleActionValidTo as any) : undefined;
    this.articleUnit = dto.articleUnit ? mls(dto.articleUnit) : '';
    this.brand = dto.brand ? dto.brand : '';
    this.exclusiveness = dto.exclusiveness;
    this.priceCode = dto.priceCode ? dto.priceCode : 0;
    this.sellAmount = dto.sellAmount ? dto.sellAmount : 0;
    this.mainArticleId = dto.mainArticleId ? dto.mainArticleId : 0;
    this.hagr = dto.hagr ? mls(dto.hagr) : '';
    this.articleText = dto.articleText ? mls(dto.articleText) : '';
    this.brandLabel = dto.brandLabel ? dto.brandLabel : undefined;
    this.wineVintage = dto.wineVintage ? dto.wineVintage : undefined;
    this.drainedNetWeight = dto.drainedWeight ? dto.drainedWeight : undefined;
    this.storability = dto.storability ? dto.storability : undefined;
    this.servingTemperature = dto.servingTemperature ? dto.servingTemperature : undefined;
    this.grapes = mls([dto.grapesDE, dto.grapesFR, dto.grapesIT]);
    this.fitsWellWith = mls([dto.fitsWellWithDE, dto.fitsWellWithFR, dto.fitsWellWithIT]);
    this.fishingZone = mls([dto.fishingZoneDE, dto.fishingZoneFR, dto.fishingZoneIT]);
    this.fishingTechnique = mls([dto.fishingTechniqueDE, dto.fishingTechniqueFR, dto.fishingTechniqueIT]);
    this.inAquaKulturGewonnen = dto.inAquaKulturGewonnen;
    this.orderNote = dto.orderNote ? mls(dto.orderNote) : undefined;
    this.description = dto.description ? mls(dto.description) : undefined;
    this.specialDiet = dto.specialDiet ? mls(dto.specialDiet) : undefined;
    this.sellUnit = dto.sellUnit ? mls(dto.sellUnit) : undefined;
    this.herkunftLabel = dto.herkunftLabel ? dto.herkunftLabel : undefined;
    this.herkunftLabelText = mls([dto.herkunftLabelDE, dto.herkunftLabelFR, dto.herkunftLabelIT]);
    this.fairtradeLabel = dto.fairtradeLabel ? dto.fairtradeLabel : undefined;
    this.fairtradeLabelText = mls([dto.fairtradeLabelDE, dto.fairtradeLabelFR, dto.fairtradeLabelIT]);
    this.nachhaltigkeitLabel = dto.nachhaltigkeitLabel ? dto.nachhaltigkeitLabel : undefined;
    this.nachhaltigkeitLabelText = mls([dto.nachhaltigkeitLabelDE, dto.nachhaltigkeitLabelFR, dto.nachhaltigkeitLabelIT]);
    this.spezialproduktLabel = dto.spezialproduktLabel ? dto.spezialproduktLabel : undefined;
    this.spezialproduktLabelText = mls([dto.spezialproduktLabelDE, dto.spezialproduktLabelFR, dto.spezialproduktLabelIT]);
    this.bioLabel = dto.bioLabel ? dto.bioLabel : undefined;
    this.bioLabelText = mls([dto.bioLabelDE, dto.bioLabelFR, dto.bioLabelIT]);
    this.palmoelLabel = dto.palmoelLabel ? dto.palmoelLabel : undefined;
    this.palmoelLabelText = mls([dto.palmoelLabelDE, dto.palmoelLabelFR, dto.palmoelLabelIT]);
    this.fakturaText = mls([dto.fakturaTextDE, dto.fakturaTextFR, dto.fakturaTextIT]);
    this.noveltyUntil = dto.noveltyUntil;
    this.articleAssortmentIndex = dto.articleAssortmentIndex ? dto.articleAssortmentIndex : [];
    this.zzArticles = dto.zzArticles && dto.zzArticles.length > 0 ? dto.zzArticles.map((a) => Article.createFromDto(a, amount)) : [];
    this.articleStatus = dto.articleStatus ? dto.articleStatus : '';
    this.mainArticleKind = dto.mainArticleKind ? dto.mainArticleKind : undefined;
    this.amount = amount ? amount : dto.amount ? dto.amount : 0;
    this.externalArticle = dto.externalArticle;
    this.available = dto.available;
    this.priceSteps = dto.priceSteps ? dto.priceSteps : undefined;
    this.specialNextDeliveryDate = dto.specialNextDeliveryDate ? new Date(dto.specialNextDeliveryDate as any) : undefined;
    this.rohstoffHerkunft = dto.rohstoffHerkunft ? fixCommas(mls(dto.rohstoffHerkunft)) : undefined;
    this.herkunftHergestelltIn = dto.herkunftHergestelltIn ? fixCommas(mls(dto.herkunftHergestelltIn)) : undefined;
    this.herkunftAbgefuellt = dto.herkunftAbgefuellt ? fixCommas(mls(dto.herkunftAbgefuellt)) : undefined;
    this.herkunftAbgepacktIn = dto.herkunftAbgepacktIn ? fixCommas(mls(dto.herkunftAbgepacktIn)) : undefined;
    this.aufgetaut = dto.aufgetaut ? fixCommas(mls(dto.aufgetaut)) : undefined;
    this.lateinName = dto.lateinName ? dto.lateinName : undefined;
    this.supplierGroupMinOrderValue = dto.supplierGroupMinOrderValue ? dto.supplierGroupMinOrderValue : undefined;
    this.supplierGroupName = dto.supplierGroupName ? dto.supplierGroupName : undefined;
    this.supplierGroupTermsDocumentName = dto.supplierGroupTermsDocumentName ? dto.supplierGroupTermsDocumentName : undefined;
    this.qsReleased = dto.qsReleased;
    this.hwgId = dto.hwgId;
    this.allergensContain = mls([dto.allergenContainDE, dto.allergenContainFR, dto.allergenContainIT]);
    this.allergensMayContain = mls([dto.allergenMayContainDE, dto.allergenMayContainFR, dto.allergenMayContainIT]);
    this.ingredients = mls(dto.ingredients) ? mls(dto.ingredients) : dto.ingredients[0];
    this.energyKcal = dto.energyKcal ? dto.energyKcal : undefined;
    this.carbohydrates = dto.carbohydrates ? dto.carbohydrates : undefined;
    this.energyKj = dto.energyKj ? dto.energyKj : undefined;
    this.sugar = dto.sugar ? dto.sugar : undefined;
    this.fat = dto.fat ? dto.fat : undefined;
    this.dietaryfibres = dto.dietaryfibres ? dto.dietaryfibres : undefined;
    this.saturatedFatty = dto.saturatedFatty ? dto.saturatedFatty : undefined;
    this.monoUnsaturatedFatty = dto.monoUnsaturatedFatty ? dto.monoUnsaturatedFatty : undefined;
    this.polyUnsaturatedFatty = dto.polyUnsaturatedFatty ? dto.polyUnsaturatedFatty : undefined;
    this.salt = dto.salt ? dto.salt : undefined;
    this.protein = dto.protein ? dto.protein : undefined;
    this.storage = mls(dto.storage) ? mls(dto.storage) : dto.storage[0];
    this.durability = mls(dto.durability) ? mls(dto.durability) : dto.durability[0];
    this.preparation = mls(dto.preparation) ? mls(dto.preparation) : dto.preparation[0];
    this.hwg = dto.hwg ? mls(dto.hwg) : '';
    this.agr = dto.agr ? mls(dto.agr) : '';
    this.managedAssortmentGroupId = dto.managedAssortmentGroupId ? dto.managedAssortmentGroupId : undefined;
    this.assortmentIndex = dto.assortmentIndex;
    this.ledgerAccount = dto.ledgerAccount ? dto.ledgerAccount : undefined;
    this.ean = dto.ean;
    this.notInWebShop = dto.notInWebShop;
    this.inSalesOrderSet = dto.inSalesOrderSet;

    this.substituteArticleId = dto.substituteArticleId ? dto.substituteArticleId : undefined;

    this.regularPrice = dto.regularPrice;
    this.effectivePrice = dto.effectivePrice;
    if (dto.articleNumber === OILBARREL_ARTICLE_NUMBER) {
      this.effectivePrice = 0;
    }

    this.isAction = dto.isAction;
    this.isIndividualPrice = dto.isIndividualPrice;

    this.alcoholVolPercent = dto.alcoholVolPercent;
    this.showAction = dto.showAction;
    this.stopselling = dto.stopselling;
    this.orderEndTimes = dto.orderEndTimes ?? undefined;

    this.isDeepLoaded = true;
  }

  get zzIndex() {
    return this._zzIndex;
  }
  set zzIndex(value) {
    runInAction(() => {
      this._zzIndex = value;
    });
  }

  updateAmountProperty(value: number) {
    this.amount = value;
    if (this.isZzArticle) {
      this.zzArticles.forEach((zz) => zz.updateAmountProperty(value));
    }
  }

  updateIsSelectedProperty(isSelected: boolean) {
    this.isSelected = isSelected;
  }

  fetchArticleDeepLoaded(isLoggedIn: boolean, loadLedger?: boolean) {
    if (this.isDeepLoading) {
      return;
    }

    this.isDeepLoading = true;

    let url = ppConstants.API_HOST_PUB + 'articles/articledetail';
    if (isLoggedIn) {
      url = ppConstants.API_HOST_SEC + 'articles/articledetail';
    }

    const mainArticleIds: number[] = [];

    if (this.isZzArticle) {
      for (const article of this.zzArticles) {
        mainArticleIds.push(article.mainArticleId);
      }
    } else {
      mainArticleIds.push(this.mainArticleId);
    }

    executeAsyncAction(
      async () => {
        const response = await axios.get(url, {
          params: {
            mainArticleIds,
            loadLedgerAccs: loadLedger
          }
        });

        const articleDetails = response.data as IArticleDetailDTOData[];

        if (Array.isArray(articleDetails)) {
          Article.updateArticleAfterDeepLoaded(articleDetails, this);

          if (this.isZzArticle) {
            for (const zzArticle of this.zzArticles) {
              Article.updateArticleAfterDeepLoaded(articleDetails, zzArticle);
            }
          }
        }
      },
      false,
      {
        errorAction: (e) => {
          console.log(`Error while deep loading articles (mainArticleIds: ${mainArticleIds})`, e);
        },
        finallyAction: () => {
          runInAction(() => {
            this.isDeepLoading = false;
          });
        }
      }
    );
  }

  getSecondaryText = (isLargeScreenUp: boolean) => {
    const array = [];
    if (this.articleNumber) {
      array.push(`${i18n.t('Art. Nr.')} ${this.articleNumber}`);
    }
    if (this.hasBrand) {
      array.push(`${i18n.t('Marke')}: ${this.brand}`);
    }

    if (this.rohstoffHerkunft && isLargeScreenUp) {
      array.push(`${i18n.t('Rohstoffherkunft')}: ${this.rohstoffHerkunft}`);
    }

    if (this.herkunftHergestelltIn && isLargeScreenUp) {
      array.push(`${i18n.t('Produktionsland')}: ${this.herkunftHergestelltIn}`);
    }

    if (this.herkunftAbgefuellt && isLargeScreenUp) {
      array.push(`${i18n.t('Artikel abgefüllt in')}: ${this.herkunftAbgefuellt}`);
    }

    if (this.herkunftAbgepacktIn && isLargeScreenUp) {
      array.push(`${i18n.t('Artikel abgepackt in')}: ${this.herkunftAbgepacktIn}`);
    }

    return array.join(' | ');
  };

  get priceAndDeliveryText() {
    const array = [];

    array.push(`${i18n.t('Liefereinheit')}: ${this.formattedSellUnitAmount}`);

    if (this.articleOrZzArticle.specialNextDeliveryDate) {
      array.push(`${i18n.t('Frühester Liefertermin')}: ${formatDate(this.specialNextDeliveryDate!)} `);
    }

    if (this.articleOrZzArticle.isProfitPlus) {
      array.push(`${i18n.t('Es gelten spezielle Preis- und Lieferkonditionen!')}`);
    }

    return array.join(' | ');
  }

  get formattedComparisonPrice() {
    return `CHF ${this.comparisonPrice ? this.comparisonPrice.toFixed(2) : 0.0} / ${this.comparisonPriceUnit}`;
  }

  get formattedEffectivePrice() {
    return `CHF ${this.effectivePrice ? this.effectivePrice.toFixed(2) : 0.0} ${this.sellUnit ? '/' : ''} ${this.sellUnit}`;
  }

  get formattedPriceArticleUnit() {
    return `CHF ${this.effectivePrice ? this.effectivePrice.toFixed(2) : 0.0} ${this.articleUnit ? '/' : ''} ${this.articleUnit}`;
  }

  get hasNutritionalValues(): boolean {
    return nutritionalValues.findIndex((value: NutritionalValue) => (this as any)[value.valueKey] !== undefined) > -1;
  }

  get hasPriceSteps(): boolean {
    return (this.priceSteps && this.priceSteps.length > 0) ?? false;
  }

  get displayAsAction() {
    return this.isAction && this.showAction;
  }

  get isProfitPlus() {
    return this.articleStatus === 's';
  }

  private static updateArticleAfterDeepLoaded(articleDetails: IArticleDetailDTOData[], article: Article) {
    const foundArticle = articleDetails.find((detail) => detail.mainArticleId === article.mainArticleId);
    if (foundArticle && foundArticle.article) {
      article.update(foundArticle);
    } else {
      runInAction(() => {
        article.notInWebShop = true;
        article.isDeepLoaded = true;
      });
    }
  }
}
