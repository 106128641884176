import FilterIcon from 'mdi-material-ui/FilterVariant';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, FormControlLabel, Popover } from '@mui/material';
import { useLogin } from '../../useLogin';
import { TgsSwitch } from '../../../components/base/TgsSwitch';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from '@uidotdev/usehooks';
import { QUERY_SEARCH_PARAM_SALESORDERSET } from '../useSearchQueryParams';
import { SESSION_STORAGE_KEY_SALESORDERSET_FILTER } from '../../../storageKey';

export function QuickSearchFilterButton() {
  const { t } = useTranslation();
  const { isLoggedIn } = useLogin();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasSalesOrderSetFilter, setHasSalesOrderSetFilter] = useSessionStorage(SESSION_STORAGE_KEY_SALESORDERSET_FILTER, false);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasSalesOrderSetFilter(event.target.checked);
    setSearchParams((prev) => {
      prev.set(QUERY_SEARCH_PARAM_SALESORDERSET, event.target.checked.toString());
      return prev;
    });
    setAnchorEl(null);
  };

  if (!isLoggedIn) {
    return null;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <>
      <IconButton id={id} aria-label={t('filter')} onClick={handleButtonClick}>
        <Badge color="primary" variant="dot" invisible={!hasSalesOrderSetFilter}>
          <FilterIcon color={hasSalesOrderSetFilter ? 'primary' : 'secondary'} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ p: 20 }}
      >
        <Box p={2}>
          <FormControlLabel control={<TgsSwitch onChange={handleFilterChange} sx={{ mx: 1 }} color={'secondary'} checked={hasSalesOrderSetFilter ?? false} />} label={t('Gekaufte Artikel')} disableTypography={true} />
        </Box>
      </Popover>
    </>
  );
}
