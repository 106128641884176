import { useContext } from 'react';
import { StoreContext } from '../StoreContext';

export function useLogin() {
  const { userStore } = useContext(StoreContext);

  const isLoggedIn = userStore.isLoggedIn;
  const loginUser = userStore.loginUser;
  const selectedCustomer = userStore.selectedCustomer;
  const actionExclude = userStore.actionExclude;

  // Wenn dem Kunden explizit eine Bestellzeitgruppe zugewiesen ist (ProlixPro -> Kunde -> Tab "Belieferung" -> Kasten "Bestellung"),
  // dann ist der Wert `orderTimetableGroupId` gesetzt. Ansonsten ist der Wert undefined (null in der DB).
  const hasIndividualOrderEndTimes = !!(userStore.loginCustomer?.orderTimetableGroupId ?? undefined);

  return { isLoggedIn, loginUser, selectedCustomer, hasIndividualOrderEndTimes, actionExclude };
}
