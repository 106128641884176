import { ISearchRequestPagingDto } from '../../../api/hub/hub.models.generated';

export const defaultPageSize: number = 50;

export function getDefaultSearchRequest(): ISearchRequestPagingDto {
  return {
    page: 0,
    pageSize: defaultPageSize,
    searchTerm: '',
    brands: [],
    rohstoffHerkunft: [],
    hergestellt: [],
    ecoScore: [],
    labels: [],
    specialDiet: [],
    allergene: []
  };
}
