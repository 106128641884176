import { Button, Dialog, DialogContent, DialogTitle, Grid, Alert, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../StoreContext';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  return {
    dialog: {
      position: 'relative',
      top: '-25%'
    },
    dialogButton: {
      minWidth: 100,
      marginLeft: 7
    },
    agbLink: {
      fontSize: 'larger'
    }
  };
});

export function NoAccessScreen() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { userStore } = useContext(StoreContext);

  async function handleBacktoLogin() {
    await userStore.performLogout();
  }

  let selectedCustomerDisplay = '';
  if (userStore.selectedCustomer) {
    selectedCustomerDisplay = userStore.selectedCustomer?.customerId + '/' + userStore.selectedCustomer.customerCategory;
  }

  return (
    <Dialog open={true} fullScreen={fullScreen} classes={fullScreen ? undefined : { paper: classes.dialog }}>
      <DialogTitle id="simple-dialog-title">{t('Zugriff nicht möglich!')}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Alert severity="info">
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`Der Kunde <strong> {{selectedCustomer}} </strong> kann nicht auf den Webshop zugreifen.`, { selectedCustomer: selectedCustomerDisplay })
                }}
              />
              <div>{userStore.webshopAccessError}</div>
            </Alert>
          </Grid>

          <Grid item>
            <Grid container direction="row-reverse" justifyContent="space-between">
              <Grid item>
                <Button variant="contained" color="primary" type="submit" className={classes.dialogButton} onClick={handleBacktoLogin} id="cancel">
                  {t('Logout')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
