import React from 'react';
import { FEATURES, FeatureStore } from './common/stores/featureStore';
import Overview from './overview/Overview';
import { Navigate, RouteObject } from 'react-router-dom';
import { RequireAuth } from './PrivateRoute';
import { UserStore } from './user/stores/userStore';
import { QueryClient } from '@tanstack/react-query';

const PromotionPreviewOverview = React.lazy(() => import(/* webpackChunkName: "PromotionPreviewOverview" */ './overview/PromotionPreviewOverview'));
const PromotionScreen = React.lazy(() => import(/* webpackChunkName: "PromotionScreen" */ './overview/PromotionScreen'));
const CareScreen = React.lazy(() => import(/* webpackChunkName: "CareScreen" */ './overview/CareScreen'));
const CatalogScreen = React.lazy(() => import(/* webpackChunkName: "CatalogScreen" */ '../modules/catalog/components/CatalogScreen'));
const CartScreen = React.lazy(() => import(/* webpackChunkName: "CartScreen" */ '../modules/cart/components/CartScreen'));
const CartOrderIdRoute = React.lazy(() => import(/* webpackChunkName: "CartScreen" */ '../routes/cart/$orderId/$orderId'));
const DeliveryDaysScreen = React.lazy(() => import(/* webpackChunkName: "DeliveryDaysScreen" */ '../modules/cart/components/deliverydays/DeliveryDaysScreen'));
const UserSettingsScreen = React.lazy(() => import(/* webpackChunkName: "UserSettingsScreen" */ '../modules/user/components/UserSettingsScreen'));
const HwgDetailScreen = React.lazy(() => import(/* webpackChunkName: "HwgDetailScreen" */ './overview/HwgDetailScreen'));
const ScanpoolOverviewScreen = React.lazy(() => import(/* webpackChunkName: "ScanpoolOverviewScreen" */ './submit/components/ScanpoolOverviewScreen'));
const SalesOrderSetScreen = React.lazy(() => import(/* webpackChunkName: "SalesOrderSetScreen" */ '../modules/salesOrderSet/components/SalesOrderSetScreen'));
const InvoiceListScreen = React.lazy(() => import(/* webpackChunkName: "InvoiceListScreen" */ './invoice/components/InvoiceListScreen').then((module) => ({ default: module.InvoiceListScreen })));
const ShipmentListScreen = React.lazy(() => import(/* webpackChunkName: "ShipmentListScreen" */ './shipment/components/ShipmentListScreen'));
const OciEntryScreen = React.lazy(() => import(/* webpackChunkName: "OciEntryScreen" */ './oci-entry/OciEntryScreen'));
const OciFinishScreen = React.lazy(() => import(/* webpackChunkName: "OciFinishScreen" */ './oci-entry/OciFinishScreen'));
const OrderModule = React.lazy(() => import(/* webpackChunkName: "CustomerOrderModule" */ '../modules/customerorders/components/CustomerOrderModule').then((module) => ({ default: module.CustomerOrderModule })));
const TemplateModule = React.lazy(() => import(/* webpackChunkName: "TemplateModule" */ '../modules/templates/components/TemplateModule'));
const ExternalArticleModule = React.lazy(() => import(/* webpackChunkName: "ExternalArticleModule" */ '../modules/externalarticles/components/ExternalArticleModule'));
const InventoryModule = React.lazy(() => import(/* webpackChunkName: "InventoryModule" */ '../modules/inventory/components/InventoryModule'));
const DownloadScreen = React.lazy(() => import(/* webpackChunkName: "DownloadScreen" */ '../modules/downloads/DownloadScreen'));
const SupplierModule = React.lazy(() => import(/* webpackChunkName: "SupplierModule" */ '../modules/suppliers/components/SupplierModule').then((module) => ({ default: module.SupplierModule })));
const CustomPromoScreen = React.lazy(() => import(/* webpackChunkName: "CustomPromoScreen" */ '../modules/customPromo/CustomPromoScreen'));
const CreateOrderScreen = React.lazy(() => import(/* webpackChunkName: "CreateOrderScreen" */ './submission/components/CreateOrderScreen'));

export function initRoutes(featureStore: FeatureStore, userStore: UserStore, queryClient: QueryClient): RouteObject[] {
  const routeDefinitions: RouteObject[] = [
    {
      path: '/',
      element: <Overview />
    },
    {
      path: '/promotionpreview/:id',
      element: <PromotionPreviewOverview />
    },
    {
      path: '/cart',
      element: (
        <RequireAuth>
          <CartScreen />
        </RequireAuth>
      )
    },
    {
      path: '/cart/:orderId',
      element: (
        <RequireAuth>
          <CartOrderIdRoute />
        </RequireAuth>
      ),
      loader: async (args) => {
        return (await import('../routes/cart/$orderId/$orderId.loader'))?.loader(args, userStore);
      }
    },
    {
      path: '/order/*',
      element: (
        <RequireAuth>
          <OrderModule />
        </RequireAuth>
      )
    },
    {
      path: '/templates/*',
      element: (
        <RequireAuth>
          <TemplateModule />
        </RequireAuth>
      )
    },
    {
      path: '/usersettings',
      element: (
        <RequireAuth>
          <UserSettingsScreen />
        </RequireAuth>
      )
    },
    {
      path: '/deliverydays',
      element: (
        <RequireAuth>
          <DeliveryDaysScreen />
        </RequireAuth>
      )
    },
    {
      path: '/promotion/:id',
      element: <PromotionScreen />
    },
    {
      path: '/hwg/:id',
      element: <HwgDetailScreen />
    },
    {
      path: '/care',
      element: <CareScreen />
    },
    {
      path: '/salesorderset',
      element: (
        <RequireAuth>
          <SalesOrderSetScreen />
        </RequireAuth>
      )
    },
    {
      path: '/invoices',
      element: (
        <RequireAuth>
          <InvoiceListScreen />
        </RequireAuth>
      )
    },
    {
      path: '/shipments',
      element: (
        <RequireAuth>
          <ShipmentListScreen />
        </RequireAuth>
      )
    },
    {
      path: '/ocientry',
      element: <OciEntryScreen />
    },
    {
      path: '/ocifinish',
      element: <OciFinishScreen />
    },
    {
      path: '/suppliers/*',
      element: (
        <RequireAuth>
          <SupplierModule />
        </RequireAuth>
      )
    },
    {
      path: '/catalog/:articleNumber',
      loader: async (args) => {
        return (await import('../routes/catalog/$articleNumber/$articleNumber.loader'))?.loader(args);
      }
    },
    {
      path: '/catalog',
      element: <CatalogScreen />,
      loader: async (args) => {
        return (await import('../routes/catalog/catalog.loader'))?.loader(args, userStore, featureStore, queryClient);
      }
    },
    {
      path: '/p/:c',
      element: <CustomPromoScreen />
    },
    {
      path: '/createorder',
      element: (
        <RequireAuth>
          <CreateOrderScreen />
        </RequireAuth>
      )
    }
  ];

  routeDefinitions.push({
    path: '/scanpool',
    element: (
      <RequireAuth>
        <ScanpoolOverviewScreen />
      </RequireAuth>
    )
  });

  if (featureStore.isFeatureEnabled(FEATURES.scanPool)) {
    routeDefinitions.push({
      path: '/downloads',
      element: <DownloadScreen />
    });
  }

  if (featureStore.isFeatureEnabled(FEATURES.inventory)) {
    routeDefinitions.push({
      path: '/inventory/*',
      element: (
        <RequireAuth>
          <InventoryModule />
        </RequireAuth>
      )
    });
  }

  if (featureStore.isFeatureEnabled(FEATURES.externalArticle)) {
    routeDefinitions.push({
      path: '/externalarticles/*',
      element: (
        <RequireAuth>
          <ExternalArticleModule />
        </RequireAuth>
      )
    });
  }

  // catch all route
  routeDefinitions.push({
    path: '/*',
    element: <Navigate to="/" replace />
  });

  return routeDefinitions;
}
