import { ppConstants } from '../../../util/ppConstants';

export let API: ReturnType<typeof createEndpointUrls>;

export function initSearchApi() {
  API = createEndpointUrls();
}

function createEndpointUrls() {
  return {
    ARTICLE_SEARCH: ppConstants.API_HOST_HUB + 'search-articles/search',
    ARTICLE_SUGGEST: ppConstants.API_HOST_HUB + `search-articles/suggest`,

    CATALOG_COMPLETE: ppConstants.API_HOST_HUB + 'search-articles/catalog',
    CATALOG_SEARCH: ppConstants.API_HOST_HUB + 'search-articles/search-catalog',

    ARTICLEINFO_PRICES: ppConstants.API_HOST_HUB + 'articleinfo/prices',
    ARTICLEINFO_SPECIALDELIVERYDATE: ppConstants.API_HOST_HUB + 'articleinfo/specialdeliverydates',
    ARTICLEINFO_ORDERENDTIMES: ppConstants.API_HOST_HUB + 'articleinfo/orderendtimes',
    ARTICLEINFO_PRICE: ppConstants.API_HOST_HUB + 'articleinfo/price',

    SEARCHFILTER_HWGS: ppConstants.API_HOST_HUB + 'search/filters/hwgs',
    SEARCHFILTER_BRANDS: ppConstants.API_HOST_HUB + 'search/filters/brands',
    SEARCHFILTER_OWN_BRANDS: ppConstants.API_HOST_HUB + 'search/filters/ownbrands',
    SEARCHFILTER_ROHSTOFFHERKUNFT: ppConstants.API_HOST_HUB + 'search/filters/rohstoffherkunft',
    SEARCHFILTER_HERGESTELLT: ppConstants.API_HOST_HUB + 'search/filters/hergestellt',
    SEARCHFILTER_ECOSORE: ppConstants.API_HOST_HUB + 'search/filters/ecoscores',
    SEARCHFILTER_LABELS: ppConstants.API_HOST_HUB + 'search/filters/labels',
    SEARCHFILTER_SUPPLIERGROUPS: ppConstants.API_HOST_HUB + 'search/filters/suppliergroups',
    SEARCHFILTER_TGS_SUPPLIERGROUPS: ppConstants.API_HOST_HUB + 'search/filters/tgssuppliergroups',
    SEARCHFILTER_SPECIALDIET: ppConstants.API_HOST_HUB + 'search/filters/specialdiet',
    SEARCHFILTER_SPECIALASSORTMENTS: ppConstants.API_HOST_HUB + 'search/filters/specialassortments',
    SEARCHFILTER_TK: ppConstants.API_HOST_HUB + 'search/filters/tk',
    SEARCHFILTER_NOVELTIES: ppConstants.API_HOST_HUB + 'search/filters/novelties',
    SEARCHFILTER_SALESORDERSET: ppConstants.API_HOST_HUB + 'search/filters/salesorderset',
    SEARCHFILTER_ACTIONS: ppConstants.API_HOST_HUB + 'search/filters/actions',
    SEARCHFILTER_ALLERGEN: ppConstants.API_HOST_HUB + 'search/filters/allergen'
  };
}
