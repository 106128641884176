import { useQuery } from '@tanstack/react-query';
import axios from '../../../util/axios';
import { qkArticleSuggestions } from './searchQueryKeys';
import { getDefaultSearchRequest } from '../model/searchModel';
import { ISearchRequestDto, ISuggestTermDto } from '../../../api/hub/hub.models.generated';
import { API } from './apiEndpoints';
import { executeAsyncActionNew } from '../../../util/exceptionHandler';

async function fetchArticleSuggestions(searchRequest: ISearchRequestDto): Promise<ISuggestTermDto[]> {
  if (!searchRequest.searchTerm || searchRequest.searchTerm.length < 3) {
    return [];
  }
  const response = await axios.post<ISearchRequestDto, any>(API.ARTICLE_SUGGEST, searchRequest);
  return response.data;
}

export function useArticleSuggestions(debouncedSearchTerm: string, minSearchTermLength: number) {
  const suggestionsQuery = useQuery({
    queryKey: [qkArticleSuggestions, debouncedSearchTerm],
    queryFn: () => executeAsyncActionNew(async () => await fetchArticleSuggestions({ ...getDefaultSearchRequest(), searchTerm: debouncedSearchTerm })),
    enabled: debouncedSearchTerm?.length >= minSearchTermLength
  });

  const hasSuggestions = !!suggestionsQuery.data && suggestionsQuery.data?.length > 0;
  const articleSuggestions = suggestionsQuery.data ?? [];
  return { hasSuggestions, articleSuggestions };
}
