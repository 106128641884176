import { InternalAxiosRequestConfig } from 'axios';
import i18n from 'i18next';
import Keycloak, { KeycloakOnLoad } from 'keycloak-js';
import { makeAutoObservable, observable, runInAction } from 'mobx';
import type { ICustomerPresentationData, IDeliveryDateData, IWebshopAuthenticationDTOData, IWebshopCustomerData, IWebshopUserData, IWebTemplateDTOData } from '../../../api';
import RootStore from '../../../rootStore';
import axios from '../../../util/axios';
import { ppConstants, PP_URL } from '../../../util/ppConstants';
import { PPCONTEXT } from '../../../util/ppContext';
import UserService from '../services/userService';
import { rootStore } from '../../../StoreContext';
import { sentryCaptureMessage } from '../../../tracking/tracking';
import { SESSION_STORAGE_KEY_SALESORDERSET_FILTER } from '../../../storageKey';

type KundenTyp = 'AGH' | 'BGH' | 'MULTICHANNEL';

const LOCALSTORAGE_REFRESH_TOKEN_TIMESTAMP = 'PP_REFRESH_TOKEN_TIMESTAMP';

const LOCALSTORAGE_KEY_SSO_LOCALE = 'PP_SSO_LOCALE';
function performLanguageUpdateFromSsoToken(token: any) {
  // the locale from the token is remembered in local-storage
  // if the locale on the token changes, then the ui-language is changed
  // if the locale on the token did not change, then the ui-language is not changed (but it might be another langauage than the locale on the token if it was changed by the user)
  const keycloakLocaleStored = localStorage.getItem(LOCALSTORAGE_KEY_SSO_LOCALE);
  const keycloakLocaleToken = token?.locale;
  //update only when language changes in Keycloak
  if (keycloakLocaleToken && (!keycloakLocaleStored || keycloakLocaleStored !== keycloakLocaleToken)) {
    i18n.changeLanguage(keycloakLocaleToken);
    localStorage.setItem(LOCALSTORAGE_KEY_SSO_LOCALE, keycloakLocaleToken);
  }
}

// The structure of the TransgourmetTokenData is defined in the Keycloak implementation (com.transgourmet.keycloak.service.tokenmapper.dto.TransgourmetTokenData)
interface TransgourmetTokenData {
  selectedCustomer: {
    kundenNummer: string;
    kundenTyp: KundenTyp;
  };
  associatedCustomersCount: number;
  user: {
    userLoginname: string;
  };
}

export interface ILoginInfo {
  basicToken: string;
  customerId?: number;
  customerCategory?: number;
}
export interface SelectedCustomerInfo {
  customerId: number;
  customerCategory: number;
  kundenNummer: string;
  kundenTyp: KundenTyp;
}

export class UserStore {
  rootStore: RootStore;

  error?: any;

  currentLoginname?: string;
  private currentLoginRoles: string[] = [];
  selectedCustomer?: SelectedCustomerInfo;
  associatedCustomersCount = 0;
  webshopAccessError?: string;
  forceAcceptWebshopAgb: boolean = false;

  // used in the standalone login flow - (jonas, 2021-09-13): properties können nach SSO Migration entfernt werden
  currentLogin?: ILoginInfo;
  loginUser: IWebshopUserData | undefined;
  loginWarningMessage?: string;

  loginCustomer?: ICustomerPresentationData;
  coreAssortmentId: number | undefined = undefined;
  invoicesSuppressed = false;
  isTempCash: boolean = false;
  hwgs: number[] = [];
  deliveryDates = new Map<number, IDeliveryDateData[]>();
  ledgerAccounts: any[] = [];

  showOrderingNotAllowedDialog: boolean = false;

  private axiosKeycloakAuthInterceptor: number | undefined = undefined;
  private keycloakInstance!: Keycloak.KeycloakInstance;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { deliveryDates: observable.shallow });
    this.rootStore = rootStore;
  }

  triggerShopReload() {
    window.location.replace(PP_URL.SHOP_URL);
    window.location.reload();
  }

  async triggerLoginFlow() {
    this.removeLoginData();

    // Die `login()` Methode des Keycloak Clients macht ein `history.replace()` bei der Navigation auf den SSO-Login-Screen.
    // Damit verschwindet die aktuelle Url aus der Browser History und wenn der User auf dem SSO-Login-Screen den Back-Button drückt,
    // dann navigiert der Browser auf die "vorletzte" URL".
    // Das macht in Szenarien wo ein Login *required* ist Sinn.
    // Im Webshop startet man aber anonym und loggt sich dann bewusst ein ...
    // Daher nutzen wir nicht `login()` des Keycloak Clients, sondern machen hier
    // eine explizite Browser-Navigation auf den SSO-Login-Screen, dadurch bleibt die Webshop URL in der Browser-History ...

    const loginUrl = this.keycloakInstance.createLoginUrl({
      prompt: 'login',
      locale: i18n.language // pass the locale to keycloak
    });
    window.location.assign(loginUrl);

    // the above code results in a browser redirect, so no code should follow here!
  }

  async performOciLogin() {
    // The OCI Login erfolgt auf dem OCI Login Screen, welcher vom externen Bestellsystem aufgerufen wird.
    // In der URL gibt das externe Bestellsystem username & passwort als Query Parameter mit.
    // Diese Query Parameter müssen unbedingt an den Keycloak weitergereicht werden.
    // Der OciAuthenticator im Keycloak versucht dann ein automatisches Login auszuführen mit den username & passwort Query Parametern in der redirect_uri
    const redirectUri = window.location.href;
    console.log('PERFORMING OCI LOGIN', redirectUri);
    await this.keycloakInstance.login({
      redirectUri: redirectUri
    });
    // Wenn der Benutzer noch nicht eingeloggt ist, erfolgt hier ein Browser Redirect auf den Keycloak.
    // Daher sollte hier kein weiterer Code folgen.
    return undefined;
  }

  ociFinish() {
    // Der OCI Warenkorb wird beim "abschicken" in einen String serialisiert, welcher dann auf dem ocifinish-Screen
    // an das Bestellsystem geschickt wird (mit einem plain old Form POST).
    // Im SSO Flow können wir nicht automatisch ausloggen, weil dies ein Redirect auf den Keycloak zur Folge hätte.
    // Damit würde der serialisierte OCI Warenkorb im Client-State verlorengehen.

    rootStore.router.navigate('/ocifinish');
  }

  async performChangeCustomerSSO() {
    await this.keycloakInstance.updateToken(300);
    this.keycloakInstance.login({
      prompt: 'login',
      action: 'IDENTITY_EXTENSION',
      locale: i18n.language
    });
  }

  async changePasswordSSO() {
    this.keycloakInstance.login({
      action: 'UPDATE_PASSWORD',
      prompt: 'login',
      loginHint: this.currentLoginname,
      locale: i18n.language
    });
  }

  async performLogout() {
    await UserService.performServerLogout();

    this.removeLoginData();

    // clear the filter for the sales order set
    sessionStorage.removeItem(SESSION_STORAGE_KEY_SALESORDERSET_FILTER);

    if (this.axiosKeycloakAuthInterceptor) {
      axios.interceptors.request.eject(this.axiosKeycloakAuthInterceptor);
    }
    rootStore.router.navigate('/');

    await this.keycloakInstance.logout({ redirectUri: PP_URL.SHOP_URL });
    // The Keycloak logout results in a browser redirect, so no code should follow here!
  }

  removeLoginData() {
    UserService.invalidateSessionToken();
    axios.defaults.headers.common.Authorization = undefined;

    this.currentLogin = undefined;
    this.loginUser = undefined;
    this.loginCustomer = undefined;
    this.coreAssortmentId = undefined;
    this.isTempCash = false;
    this.showOrderingNotAllowedDialog = false;
    this.loginWarningMessage = undefined;
  }

  async initializeLogin() {
    const keyCloakInitOptions = {
      url: PPCONTEXT.KEYCLOAK_HOST + '/auth',
      realm: PPCONTEXT.KEYCLOAK_REALM,
      clientId: 'tgs-webshop'
    };

    this.keycloakInstance = new Keycloak(keyCloakInitOptions);

    const initOptions = {
      onLoad: 'check-sso' as KeycloakOnLoad,
      checkLoginIframe: false
    };
    const auth = await this.keycloakInstance.init(initOptions);

    if (!auth) {
      throw new Error(`Keycloak init failed with resolved value: ${auth}`);
    } else {
      performLanguageUpdateFromSsoToken(this.keycloakInstance.tokenParsed);

      const transgourmetTokenData = this.keycloakInstance.tokenParsed!['Transgourmet'] as TransgourmetTokenData;

      const axiosSsoInterceptor = this.createSsoAccessInterceptor(this.keycloakInstance);
      this.axiosKeycloakAuthInterceptor = axios.interceptors.request.use(axiosSsoInterceptor as any);

      try {
        if (!transgourmetTokenData.selectedCustomer) {
          this.webshopAccessError = i18n.t('Beim Login des Benutzers {{0}} wurde kein Kunde ausgewählt!', { 0: transgourmetTokenData.user.userLoginname });
        }

        const customerIdString = transgourmetTokenData.selectedCustomer.kundenNummer.substring(0, 8);
        const customerCategoryString = transgourmetTokenData.selectedCustomer.kundenNummer.substring(8, 10);

        const customerId = parseInt(customerIdString, 10);
        const customerCategory = parseInt(customerCategoryString, 10);

        if (isNaN(customerId) || isNaN(customerCategory)) {
          throw new Error(`The SSO Token does not contain a valid value for "selectedCustomer.kundenNummer". The value is: ${transgourmetTokenData.selectedCustomer?.kundenNummer}`);
        }

        this.selectedCustomer = {
          customerId: customerId,
          customerCategory: customerCategory,
          kundenNummer: transgourmetTokenData.selectedCustomer.kundenNummer,
          kundenTyp: transgourmetTokenData.selectedCustomer.kundenTyp
        };

        this.currentLoginname = transgourmetTokenData.user.userLoginname;
        this.currentLoginRoles = this.keycloakInstance.realmAccess!.roles;
        this.associatedCustomersCount = transgourmetTokenData.associatedCustomersCount;

        sessionStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN_TIMESTAMP, new Date().toISOString());

        const webshopAuthenticationDto = await UserService.initWebshopConversation();
        this.updateStateFromAuthenticationDto(webshopAuthenticationDto);
      } catch (error) {
        console.error(error);
      }
    }
  }

  setUserInformation(user: IWebshopUserData) {
    this.loginUser = user;
  }

  setCustomerInformation(webshopCustomer: IWebshopCustomerData) {
    this.loginCustomer = webshopCustomer.customer;
    this.coreAssortmentId = webshopCustomer.coreAssortmentId;
    this.invoicesSuppressed = webshopCustomer.invoicesSuppressed;
    this.isTempCash = webshopCustomer.isTempCash;
    this.hwgs = webshopCustomer.hwgs;
    this.showOrderingNotAllowedDialog = this.isTempCash;

    this.setDeliveryDates(0, webshopCustomer.deliveryCalendarCurrentMonth.deliveryDates);

    this.rootStore.masterDataStore.setMessages(webshopCustomer.messages);
    this.rootStore.featureStore.setFeatureConfigFromBackend(webshopCustomer.settings);

    const temporaryTemplates = webshopCustomer.templates.map((t) => {
      return {
        id: t.id,
        isFavorit: t.favorite,
        name: t.name,
        positionCount: t.externalArticles.length + t.articles.length,
        mainArticleIds: t.mainArticleIds
      } as IWebTemplateDTOData;
    });
    this.rootStore.templateStore.setTemplates(temporaryTemplates);
    this.rootStore.inventoryStore.setInventories(webshopCustomer.inventories);
  }

  setDeliveryDates(weekOffset: number, dates: IDeliveryDateData[]) {
    dates.forEach((d: any) => (d.date = new Date(d.date)));
    this.deliveryDates.set(weekOffset, dates);
  }

  getDeliveryDates = (weekOffset: number) => this.deliveryDates.get(weekOffset);

  updateShowOrderingNotAllowedDialog(show: boolean) {
    this.showOrderingNotAllowedDialog = show;
  }

  async fetchDeliveryCalendar(weekOffset: any) {
    try {
      const result = await UserService.fetchDeliveryCalendar(weekOffset);
      this.setDeliveryDates(result.weekOffset, result.deliveryDates);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  }

  async fetchLedgerAccounts() {
    try {
      const accounts = await UserService.fetchLedgerAccounts();
      runInAction(() => {
        this.ledgerAccounts = accounts;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  }

  async acceptAGB() {
    await this.acceptAgbSsoLoginFlow();
  }

  deliveryCalendarExpired(deliveryDates: IDeliveryDateData[]) {
    this.deliveryDates.clear();
    this.setDeliveryDates(0, deliveryDates);
  }

  get isLoggedIn() {
    return !!this.loginCustomer;
  }

  get actionExclude() {
    return this.loginCustomer && this.loginCustomer.actionExclude !== null ? this.loginCustomer.actionExclude : false;
  }

  get canChangeUser() {
    return this.associatedCustomersCount > 1 || this.currentLoginRoles.includes(ppConstants.KEYKCLOAK_ROLE_WEBSHOP_ADMIN);
  }

  get ledgerAccountsLoaded() {
    return this.ledgerAccounts && this.ledgerAccounts.length > 0;
  }

  get currentCustomer() {
    if (this.loginCustomer && this.loginCustomer!.displayName && this.loginUser && (this.loginUser.webShopAdmin || this.loginUser.customerAdvisor || this.loginCustomer.agbAccepted)) {
      return this.loginCustomer;
    } else {
      return undefined;
    }
  }

  private async acceptAgbSsoLoginFlow() {
    const acceptAgbResponse = await axios.put<IWebshopAuthenticationDTOData>(ppConstants.API_HOST_SEC + 'auth/acceptagb');
    const webshopAuthenticationDto = acceptAgbResponse.data;
    this.updateStateFromAuthenticationDto(webshopAuthenticationDto);
  }

  private updateStateFromAuthenticationDto(webshopAuthenticationDto: IWebshopAuthenticationDTOData) {
    runInAction(() => {
      if (webshopAuthenticationDto.responseType === 'ERROR' || webshopAuthenticationDto.responseType === 'WARNING') {
        this.webshopAccessError = webshopAuthenticationDto.message;
      } else {
        this.setUserInformation(webshopAuthenticationDto.transgourmetUser);
        this.setCustomerInformation(webshopAuthenticationDto.webshopCustomer);
        this.forceAcceptWebshopAgb = webshopAuthenticationDto.forceAcceptWebshopAgb;
      }
    });
  }

  private createSsoAccessInterceptor(keycloak: Keycloak.KeycloakInstance) {
    return async (config: InternalAxiosRequestConfig) => {
      try {
        const refreshed = await keycloak.updateToken(ppConstants.ACCESS_TOKEN_MIN_VALIDITY);

        if (refreshed) {
          sessionStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN_TIMESTAMP, new Date().toISOString());
        }

        config.headers.Authorization = 'Bearer ' + keycloak.token;

        return config;
      } catch (e) {
        // Es kann durchaus legitim sein, dass der Token-Refresh nicht funktioniert.
        // Die aktuelle Konfiguration sollte aber so sein, dass der Token-Refresh innerhalb weniger als 24h immer funktioniert
        // Um einen Hinweis auf Probleme zu bekommen loggen wir einen Error, wenn der Token-Refresh nach weniger als 24h fehlschlägt.
        const refreshTokenTimeStampString = sessionStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN_TIMESTAMP);
        const refreshTokenTimeStamp = refreshTokenTimeStampString ? new Date(refreshTokenTimeStampString) : null;
        const isValidTimeStamp = refreshTokenTimeStamp?.getTime();
        if (refreshTokenTimeStamp && isValidTimeStamp) {
          const msSinceLastTokenRefresh = new Date().getTime() - refreshTokenTimeStamp.getTime();
          if (msSinceLastTokenRefresh < 24 * 60 * 60 * 1000) {
            sentryCaptureMessage('Failed to refresh the access token!', { msSinceLastTokenRefresh }, 'error');
          }
        }

        // Wir laden die Seite komplett neu, wenn der Token-Refresh fehlschlägt.
        // Mit dieser Variante gehen wir auf Nummer sicher, dass der User nicht in einem inkonsistenten Zustand ist.
        document.location.reload();
      }
    };
  }
}
